import React, { Component } from "react";
import {
    MDBModalBody,
    MDBModalHeader,
    MDBSelect,
    MDBSelectInput,
    MDBSelectOptions,
    MDBSelectOption,
    MDBRow,
    MDBCol,
    MDBIcon,
    MDBModal,
    toast,
    MDBProgress,
    MDBBtn,
} from "mdbreact";
import AddSubscriber from "../../../../components/NetworkMgmt/Forms/AddSubscriber/AddSubscriber";
import ViewModifySubscribers from "../../../../components/NetworkMgmt/Venues/ViewVenueSubscribers";
import ResendActivation from "../../../../components/VenueMgmt/ResendActivation/ResendActivation";
import ServicesEndingSoon from "../../../../components/VenueMgmt/UpcomingExpirations/ServicesEndingSoon";
import axios from "../../../../axios";
import { withTranslation } from "react-i18next";
import DownloadSubscribers from "../../../../components/NetworkMgmt/Forms/BulkDownloadSubscribers/BulkDownloadSubscribers";
import UploadSubscribers from "../../../../components/NetworkMgmt/Forms/BulkUploadSubscribers/BulkUploadSubscribers";
import AbandonedSubscribers from "../../../../components/NetworkMgmt/Transactions/AbandonedSubscribers";
import InvoicesTable from "../../../../components/NetworkMgmt/Transactions/Invoices";
import PaymentUpdateRequiredTable from "../../../../components/NetworkMgmt/Transactions/PaymentUpdateRequired";
import SearchSubscribers from "../../../../components/NetworkMgmt/Subscribers/SearchSubscribers";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
// import brandsData from "../../../../brands/common/brands.json";
let showSendEmail;
class SubscriberManagementNavigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalAddNewSubscriber: false,
            modalViewModifySubscriber: false,
            modalResendActivation: false,
            modalServicesEndingSoon: false,
            activeData: this.props.activeData,
            allActiveData: this.props.allActiveData,
            loading: false,
            loading_upload: false,
            loadingSearchResults: false,
            uploadSucceeded: undefined,
            lastProcessed: 0,
            unitForm: (
                <MDBSelect
                    id="selectUnit"
                    getValue={this.resetBuildingForm}
                    className="form-control"
                >
                    <MDBSelectInput
                        className="selectInput selectUnit"
                        selected="Choose your unit"
                    />
                </MDBSelect>
            ),
            buildingForm: (
                <MDBSelect
                    id="selectBuilding"
                    getValue={this.handleSelectChangeBuilding}
                    className="form-control"
                >
                    <MDBSelectInput
                        className="selectInput selectBuilding"
                        selected="Choose your building"
                    />
                </MDBSelect>
            ),
            floorForm: (
                <MDBSelect
                    id="selectFloor"
                    getValue={this.handleSelectChangeFloor}
                    className="form-control"
                >
                    <MDBSelectInput
                        className="selectInput selectFloor"
                        selected="Choose your floor"
                    />
                </MDBSelect>
            ),
            editorHeaderState: EditorState.createEmpty(),
            editorMessageState: EditorState.createEmpty(),
            emailAddresses: [],
            value: "",
            error: null,
            paymentFailed: false,
            modalAddPayment: false,
            optionTemplateUnits: [],
            optionTemplateBuilding: [],
            optionTemplateFloor: [],
            optionTemplateServicePlans: [],
            modalModifyResident: false,
            modalEndedSubscriptionInfo: false,
            setSearchBy: "email",
            clearDropDown: true
        };
        this.findSubscribers = this.findSubscribers.bind(this);
    }
    componentDidMount() {
        this.getVenues();
    }
    componentDidUpdate() {
        // if(this.state.selectedUnit!==undefined && this.state.selectedBuilding!==undefined && this.state.selectedFloor !== undefined){
        //  this.setState({
        //   unitID_internal:
        //     this.state.unitData
        //      [this.state.selectedUnit]
        //      [this.state.selectedBuilding]
        //      [this.state.selectedFloor]["UnitID_internal"],
        //  })
        // }
    }
    toggleAddNewSubscriber = () => {
        this.setState({
            modalAddNewSubscriber: !this.state.modalAddNewSubscriber,
        });
    };
    toggleViewModifySubscriber = () => {
        this.setState({
            modalViewModifySubscriber: !this.state.modalViewModifySubscriber,
        });
    };
    toggleResendActivation = () => {
        this.setState({
            modalResendActivation: !this.state.modalResendActivation,
        });
    };
    toggleServicesEndingSoon = () => {
        this.setState({
            modalServicesEndingSoon: !this.state.modalServicesEndingSoon,
        });
    };
    toggleBulkDownloadSubscribers = () => {
        this.setState({
            modalDownloadSubscribers: !this.state.modalDownloadSubscribers,
        });
    };
    toggleBulkUploadSubscribers = () => {
        this.setState({
            modalBulkUploadSubscribers: !this.state.modalBulkUploadSubscribers,
            clearDropDown: true
        });
    };
    toggleSearchSubscriber = () => {
        this.setState({
            modalSearchSubscriber: !this.state.modalSearchSubscriber,
            searchResults: undefined,
        });
    };
    toggleAbandonedSubscribersTable = () => {
        this.setState({
            modalAbandonedSubscribersTable:
                !this.state.modalAbandonedSubscribersTable,
        });
    };
    toggleInvoicesTable = () => {
        this.setState({
            modalInvoicesTable: !this.state.modalInvoicesTable,
        });
    };
    togglePaymentUpdateRequiredTable = () => {
        this.setState({
            modalPaymentUpdateRequiredTable:
                !this.state.modalPaymentUpdateRequiredTable,
        });
    };
    toggleSendEmailSubscribers = () => {
        this.setState({
            modalSendEmailSubscribers: !this.state.modalSendEmailSubscribers,
        });
    };
    toggleError = () => {
        this.setState({
            modalError: !this.state.modalError,
        });
    };
    togglePayment = () => {
        this.setState({
            modalAddPayment: !this.state.modalAddPayment,
        });
    };
    submitHandler = (
        venueID,
        dateEnd,
        email,
        firstName,
        lastName,
        phoneNumber,
        language,
        unitID,
        building,
        floor,
        contactpref_email,
        contactpref_sms,
        dateStart,
        unitID_internal,
        servicePlan,
        resubmitted
    ) => {
        let isoDateString;
        let isoDateStart;
        this.setState({
            loading: true,
            loadingRecreateSubscription: true,
        });
        if (!isNaN(new Date(dateEnd))) {
            isoDateString = new Date(dateEnd)
                .toISOString()
                .replace(/:00.\d+Z$/g, "Z");
        }
        if (!isNaN(new Date(dateStart))) {
            isoDateStart = new Date(dateStart)
                .toISOString()
                .replace(/:00.\d+Z$/g, "Z");
        }
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            EmailAddress: email,
            GivenName: firstName,
            FamilyName: lastName,
            "PhoneNumber.E164": phoneNumber,
            locale: language,
            "EndDateTimeUTC.ISO8601": isoDateString,
            "StartDateTimeUTC.ISO8601": isoDateStart,
            VenueID: venueID,
            PolicyforSubscriberKey: {
                UnitID_internal: unitID_internal,
            },
            contactpref_email: true,
            contactpref_sms: contactpref_sms,
            UserTimeZone: new Date().getTimezoneOffset(),
            ServicePlanID: servicePlan,
        };
        // console.log(body)
        axios
            .post("/venuemgmtconsole/subscribers/addSubscriber", body, {
                headers: {},
            })
            .then(
                (response) => {
                    toast.success(
                        response.data.message !== undefined
                            ? response.data.message
                            : "Added Subscriber!",
                        {
                            closeButton: false,
                        }
                    );

                    this.props.updateName(this.state.venueName, venueID);
                    this.findSubscribers();
                    this.setState({
                        loading: false,
                        loadingRecreateSubscription: false,
                    });
                    if (response.data.paymentFailed) {
                        this.setState({
                            paymentFailed: true,
                        });
                        this.togglePayment();
                        resubmitted
                            ? this.toggleAddResidentResubmit()
                            : this.toggleAddNewSubscriber();
                    } else {
                        resubmitted
                            ? this.toggleAddResidentResubmit()
                            : this.toggleAddNewSubscriber();
                    }
                    sessionStorage.setItem(
                        "unitID_internalSubscriber",
                        unitID_internal
                    );
                    sessionStorage.setItem(
                        "stripeClientSecretSubscriber",
                        response.data.StripeClientSecret
                    );
                },
                (error) => {
                    toast.error(error.response.data.message, {
                        closeButton: false,
                    });
                    this.setState({
                        loading: false,
                        loadingRecreateSubscription: false,
                    });
                }
            );
    };
    submitHandlerResendActivation = (email) => {
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            EmailAddress: email,
            VenueID: this.props.venueID,
            UserTimeZone: new Date().getTimezoneOffset(),
        };
        this.setState({ loading: true });
        axios
            .post("/venuemgmtconsole/subscribers/resendCodeforTandC", body, {
                headers: {},
            })
            .then(
                (response) => {
                    toast.success("Resent Confirmation!", {
                        closeButton: false,
                    });
                    this.toggleResendActivation();
                    this.setState({ loading: false });
                },
                (error) => {
                    toast.error("There was an error", {
                        closeButton: false,
                    });
                    this.toggleResendActivation();
                    this.setState({ loading: false });
                }
            );
    };
    getVenues = (selectedID) => {
        this.setState({
            // venueID: undefined,
            // selectedUnit: undefined,
            // selectedBuilding: undefined,
            // selectedFloor: undefined,
            // optionTemplateBuilding: [],
            // optionTemplateFloor: [],
            // optionTemplateUnits: [],
            // unitID_internal: undefined,
            // venueName: undefined,
        });
        this.setState({ attributeForm: [] });
        let body = { AccessToken: sessionStorage.getItem("AccessToken") };
        axios
            .post("/networkoperatorconsole/venues/listVenues", body, {
                headers: {},
            })
            .then((response) => {
                var formArrayVenues = [];
                let optionTemplateVenues = [];
                this.setState({ venueList: response.data.VenueList });
                Object.keys(response.data.VenueList).map((oneKey, i) => {
                    if (selectedID === undefined) {
                        this.setState({ firstID: oneKey });
                    }
                    if (selectedID === oneKey) {
                        optionTemplateVenues.push(
                            <MDBSelectOption selected value={oneKey}>
                                {response.data.VenueList[oneKey]["VenueName"]}
                            </MDBSelectOption>
                        );
                    } else {
                        optionTemplateVenues.push(
                            <MDBSelectOption value={oneKey}>
                                {response.data.VenueList[oneKey]["VenueName"]}
                            </MDBSelectOption>
                        );
                    }
                    return true;
                });
                formArrayVenues.push(
                    <MDBSelect
                        id="selectVenueSubscriberModal"
                        getValue={this.handleSelectChangeVenue}
                        className="form-control"
                    >
                        <MDBSelectInput
                            className="selectInput venueSelectedSubscriberModal"
                            selected="Choose your venue"
                        />
                        <MDBSelectOptions search>
                            {optionTemplateVenues}
                        </MDBSelectOptions>
                    </MDBSelect>
                );
                this.setState({
                    attributeForm: formArrayVenues,
                });
            })
            .catch((error) => {
                this.setState({ error: true });
            });
    };
    getVenueDetails = async (venueID) => {
        // console.log("ResidentNavigation - getVenueDetails: ", venueID)
        this.setState({
            loadingVenueDetails: true,
        });
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            VenueID:
                venueID === undefined
                    ? sessionStorage.getItem("venueID")
                    : venueID,
            UserTimeZone: new Date().getTimezoneOffset(),
        };
        this.setState({
            address1: undefined,
            address2: undefined,
            city: undefined,
            region: undefined,
            country: undefined,
            postalcode: undefined,
            unitData: undefined,
            expiringData: undefined,
            searchData: undefined,
            value: "",
            defaultSubscriptionDurationRes: 0,
            loading: true,
            unifForm: undefined,
            hasUpdated: false,
            optionTemplateUnits: [],
        });
        await axios
            .post("/networkoperatorconsole/venues/listVenueDetails", body, {
                headers: {},
            })
            .then((response) => {
                this.setState({
                    address1: response.data[venueID]["AddressLine1"],
                    address2: response.data[venueID]["AddressLine2"],
                    city: response.data[venueID]["City"],
                    region: response.data[venueID]["State"],
                    country: response.data[venueID]["Country.ISO3166A3"],
                    postalcode: response.data[venueID]["PostalCode"],
                    unitData:
                        response.data[venueID][
                            "Units-Building-Floor-List-Subscribers"
                        ],
                    defaultSubscriptionDurationRes:
                        response.data[venueID][
                            "DefaultSubscriptionDurationHours_GlobalWirelessPlusLocalWiredAccess"
                        ],
                    venueServicePlans:
                        response.data[venueID]["AvailableServicePlans"],
                    hasUpdated: true,
                    venueInfo: response.data[venueID],
                });
                // let optionTemplatePlans = [];
                // Object.keys(response.data[venueID]["AvailableServicePlans"]).map(
                //   (oneKey, i) => {
                //     optionTemplatePlans.push({
                //       value: oneKey,
                //       name: response.data[venueID]["AvailableServicePlans"][oneKey][
                //         "ServicePlanName"
                //       ],
                //     });
                //     return true;
                //   }
                // );
                // this.setState({
                //   optionTemplatePlans: optionTemplatePlans,
                //   loading_prop_groups: false,
                // });
                // this.setState({ loadingVenueDetails: false });
                let units = Object.keys(
                    response.data[venueID][
                        "Units-Building-Floor-List-Subscribers"
                    ]
                );
                let buildings = [];
                let objUnits = {};
                for (let i = 0; i < units.length; i++) {
                    buildings.push(
                        Object.keys(
                            response.data[venueID][
                                "Units-Building-Floor-List-Subscribers"
                            ][units[i]]
                        )
                    );
                    for (let j = 0; j < buildings[i].length; j++) {
                        let floor = Object.keys(
                            response.data[venueID][
                                "Units-Building-Floor-List-Subscribers"
                            ][units[i]][buildings[i][j]]
                        );
                        for (let k = 0; k < floor.length; k++) {
                            objUnits[i] =
                                response.data[venueID][
                                    "Units-Building-Floor-List-Subscribers"
                                ][units[i]][buildings[i]];
                        }
                    }
                }
                let optionTemplateUnits = [];
                Object.keys(objUnits).map((oneKey, i) => {
                    optionTemplateUnits.push({
                        value: units[oneKey],
                        name: units[oneKey],
                    });
                    return true;
                });
                let optionTemplateServicePlans = [];
                Object.keys(
                    response.data[venueID]["AvailableServicePlans"]
                ).map((value, i) => {
                    optionTemplateServicePlans.push({
                        value: response.data[venueID]["AvailableServicePlans"][
                            value
                        ]["ServicePlanID"],
                        name: response.data[venueID]["AvailableServicePlans"][
                            value
                        ]["ServicePlanName"],
                    });
                    return true;
                });
                this.setState({
                    optionTemplateUnits: optionTemplateUnits,
                    optionTemplateServicePlans: optionTemplateServicePlans,
                    loading: false,
                    clearDropDown: false,
                    loadingVenueDetails: false,
                });
            })
            .catch((error) => {
                this.setState({ loadingVenueDetails: false });
                this.setState({
                    error: true,
                    loading: false,
                });
            });
    };
    findSubscribers = async () => {
        // event.preventDefault();
        this.setState({
            loadingSearchResults: true,
            searchResults: undefined,
        });
        let body;
        switch (this.state.setSearchBy) {
            case "email": {
                body = {
                    AccessToken: sessionStorage.getItem("AccessToken"),
                    EmailAddress: this.state.subscriberEmail,
                };
                break;
            }
            case "phone": {
                body = {
                    AccessToken: sessionStorage.getItem("AccessToken"),
                    "PhoneNumber.E164": this.state.subscriberPhone,
                };
                break;
            }
            case "venue": {
                body = {
                    AccessToken: sessionStorage.getItem("AccessToken"),
                    VenueID: this.props.venueID,
                    UnitID_internal: this.state.unitID_internal,
                };
                break;
            }
            default: {
                body = {
                    AccessToken: sessionStorage.getItem("AccessToken"),
                    EmailAddress: this.state.subscriberEmail,
                };
                break;
            }
        }
        await axios
            .post(
                "/networkoperatorconsole/shoppingcart/listSubscriptions",
                body,
                {
                    headers: {},
                }
            )
            .then(
                (response) => {
                    this.setState({
                        searchResults: response.data.Subscriptions,
                    });
                    this.setState({ loadingSearchResults: false });
                    // this.updateClassName(response.data.Subscriptions);
                },
                (error) => {
                    this.setState({ loadingSearchResults: false });
                }
            );
    };
    getVenueUnitDetails = (key, venueID, index, active) => {
        // console.log("getVenueUnitDetails: ", venueID)
        this.setState({ loadingVenueDetails: true });
        if (index === undefined) {
            index = this.props.firstID;
        }
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            UnitID_internal:
                index === undefined
                    ? sessionStorage.getItem("unitID_internalSubscriber")
                    : index,
            UserTimeZone: new Date().getTimezoneOffset(),
        };
        // eslint-disable-next-line no-unused-vars
        let subscriptionID;
        this.setState({
            unitSubscriptionsList: undefined,
            SubscriberGivenName: undefined,
            SubscriberFamilyName: undefined,
            SubscriberEmail: undefined,
            SubscriberPhoneNumber: undefined,
            contactpref_email: undefined,
            contactpref_sms: undefined,
            SubscriberLocale: undefined,
            UnitFloor: undefined,
            UnitBuilding: undefined,
            SSID: undefined,
            StartDateTime: undefined,
            EndDateTime: undefined,
            UnitID: undefined,
            unitID_internal: undefined,
            servicePlanID: undefined,
            servicePlan: undefined,
            vlan: undefined,
            status: undefined,
            realpageSyncDate: undefined,
            subscriberManagedviaSSO: undefined,
            paymentFailed: undefined,
            StripeClientSecret: undefined,
            country: undefined,
            subscriptionDiscount: undefined,
            venueDiscount: undefined,
            externalSubscriberID: undefined,
            availablePlans: undefined,
            selectedServicePlanPrice: undefined,
            setupIntentID: undefined,
            active: active,
            venueUnitData: undefined,
        });
        axios
            .post("/networkoperatorconsole/venues/listVenueUnitDetails", body, {
                headers: {},
            })
            .then((response) => {
                // console.log("ResidentNav - this.state.searchResults: ", this.state.searchResults)
                subscriptionID = Object.keys(
                    response.data["VenueUnits"][0]["Subscriptions"]
                );
                sessionStorage.setItem(
                    "venueID",
                    this.state.searchResults[key]["VenueID"]
                );
                // console.log(response.data["VenueUnits"][0]["Subscriptions"])
                this.setState({
                    unitSubscriptionsList: response.data["VenueUnits"][0],
                    subscriptionID: Object.keys(
                        response.data["VenueUnits"][0]["Subscriptions"]
                    ),
                    venueID: response.data["VenueUnits"][0]["Venue"]["VenueID"],
                    venueUnitData: response.data["VenueUnits"],
                });
                this.setState({ loadingVenueDetails: false });

                if (active === "Active") {
                    this.setState({
                        venueName: this.state.searchResults[key].VenueName,
                        SubscriberGivenName:
                            this.state.searchResults[key].GivenName,
                        SubscriberFamilyName:
                            this.state.searchResults[key].FamilyName,
                        SubscriberEmail:
                            this.state.searchResults[key].EmailAddress,
                        SubscriberPhoneNumber:
                            this.state.searchResults[key]["PhoneNumber.E164"],
                        contactpref_email:
                            this.state.searchResults[key].contactpref_email,
                        contactpref_sms:
                            this.state.searchResults[key].contactpref_sms,
                        SubscriberLocale: this.state.searchResults[key].locale,
                        UnitFloor:
                            this.state.unitSubscriptionsList["UnitFloor"],
                        UnitBuilding:
                            this.state.unitSubscriptionsList["UnitBuilding"],
                        SSID: this.state.searchResults[key].ssid,
                        StartDateTime:
                            this.state.unitSubscriptionsList["Subscriptions"][
                                this.state.subscriptionID
                            ]["StartDateTimeUTC.ISO8601"],
                        EndDateTime:
                            this.state.unitSubscriptionsList["Subscriptions"][
                                this.state.subscriptionID
                            ]["EndDateTimeUTC.ISO8601"],
                        UnitID: this.state.unitSubscriptionsList["UnitID"],
                        unitID_internal:
                            this.state.unitSubscriptionsList["UnitID_internal"],
                        servicePlanID:
                            this.state.unitSubscriptionsList["Subscriptions"][
                                this.state.subscriptionID
                            ]["ServicePlan"]["ServicePlanID"],
                        servicePlan:
                            this.state.unitSubscriptionsList["Subscriptions"][
                                this.state.subscriptionID
                            ]["ServicePlan"]["ServicePlanName"],
                        vlan: this.state.unitSubscriptionsList["vlan"],
                        status: this.state.searchResults[key]
                            .AccountStatusAtCreation,
                        realpageSyncDate:
                            this.state.unitSubscriptionsList["Subscriptions"][
                                this.state.subscriptionID
                            ]["RealPageSyncDateTimeUTC.ISO8601"],
                        subscriberManagedviaSSO:
                            this.state.searchResults[key][
                                "SubscriberManagedviaSSO"
                            ],
                        paymentFailed:
                            this.state.searchResults[key]["paymentFailed"],
                        StripeClientSecret:
                            this.state.searchResults[key]["StripeClientSecret"],
                        country:
                            this.state.searchResults[key]["Country.ISO3166A3"],
                        subscriptionDiscount:
                            this.state.searchResults[key][
                                "VenueUnitPriceAdjustment"
                            ],
                        venueDiscount:
                            this.state.searchResults[key][
                                "VenuePriceAdjustment"
                            ],
                        externalSubscriberID:
                            this.state.searchResults[key][
                                "externalSubscriberID"
                            ],
                        availablePlans:
                            this.state.unitSubscriptionsList[
                                "AvailableServicePlans"
                            ],
                        selectedServicePlanPrice:
                            this.state.searchResults[key][
                                "ListBillingCyclePrice.USD"
                            ],
                        stripeClientParentSecret:
                            this.state.searchResults[key]["StripeClientSecret"],
                        setupIntentID:
                            this.state.searchResults[key]["SetupIntentId"],
                        active: "true",
                    });
                    this.toggleModifyResident(active);
                } else {
                    this.setState({
                        venueName: this.state.searchResults[key].VenueName,
                        SubscriberGivenName:
                            this.state.searchResults[key].GivenName,
                        SubscriberFamilyName:
                            this.state.searchResults[key].FamilyName,
                        SubscriberEmail:
                            this.state.searchResults[key].EmailAddress,
                        SubscriberPhoneNumber:
                            this.state.searchResults[key]["PhoneNumber.E164"],
                        contactpref_email:
                            this.state.searchResults[key].contactpref_email,
                        contactpref_sms:
                            this.state.searchResults[key].contactpref_sms,
                        SubscriberLocale: this.state.searchResults[key].locale,
                        StartDateTime:
                            this.state.searchResults[key][
                                "StartDateTimeUTC.ISO8601"
                            ],
                        EndDateTime:
                            this.state.searchResults[key][
                                "EndDateTimeUTC.ISO8601"
                            ],
                        unitID_internal:
                            this.state.searchResults[key]["UnitID_internal"],
                        servicePlanID:
                            this.state.searchResults[key]["ServicePlanID"],
                        servicePlan:
                            this.state.searchResults[key]["ServicePlanName"],
                        // vlan: this.state.unitSubscriptionsList["vlan"],
                        // status: this.state.searchResults[key].AccountStatusAtCreation,
                        realpageSyncDate:
                            this.state.searchResults[key][
                                "RealPageSyncDateTimeUTC.ISO8601"
                            ],
                        // subscriberManagedviaSSO:
                        //   this.state.searchResults[key]["SubscriberManagedviaSSO"],
                        // paymentFailed: this.state.searchResults[key]["paymentFailed"],
                        StripeClientSecret:
                            this.state.searchResults[key]["StripeClientSecret"],
                        country:
                            this.state.searchResults[key]["Country.ISO3166A3"],
                        subscriptionDiscount:
                            this.state.searchResults[key][
                                "VenueUnitPriceAdjustment"
                            ],
                        venueDiscount:
                            this.state.searchResults[key][
                                "VenuePriceAdjustment"
                            ],
                        externalSubscriberID:
                            this.state.searchResults[key][
                                "externalSubscriberID"
                            ],
                        availablePlans:
                            this.state.unitSubscriptionsList[
                                "AvailableServicePlans"
                            ],
                        selectedServicePlanPrice:
                            this.state.searchResults[key][
                                "ListBillingCyclePrice.USD"
                            ],
                        stripeClientParentSecret:
                            this.state.searchResults[key]["StripeClientSecret"],
                        setupIntentID:
                            this.state.searchResults[key]["SetupIntentId"],
                        active: "false",
                    });
                    this.toggleEndedSubscriptionInfo(active);
                }
                this.getVenueDetails(venueID);
            })
            .catch((error) => {
                this.setState({ error: true });
                this.setState({ loadingVenueDetails: false });
            });
    };
    handleSelectChangeVenue = (value, name) => {
        // console.log("handleSelectChangeValue: ", value)
        this.setState({
            optionTemplateUnits: [],
            optionTemplateBuilding: [],
            optionTemplateFloor: [],
            unitID_internal: undefined,
            selectedUnit: undefined,
        });
        var venue = name;
        var lDiv = document.createElement("div");
        document.body.appendChild(lDiv);
        lDiv.style.fontSize = "" + 20 + "px";
        lDiv.style.position = "absolute";
        lDiv.style.left = -1000;
        lDiv.style.top = -1000;
        lDiv.innerHTML = venue;
        document.body.removeChild(lDiv);
        lDiv = null;
        sessionStorage.setItem("venueName", venue);
        sessionStorage.setItem("venueID", value);
        // console.log("Updated Venue: ", value)
        this.getVenueDetails(value);
        this.setState({ selectedID: value });
        this.updateName(venue, value);
    };
    handleSelectChangeVenueFromList = (venueName, venueID) => {
        sessionStorage.setItem("venueName", venueName);
        sessionStorage.setItem("venueID", venueID);
        this.getVenues(venueID);
        this.setState({ selectedID: venueID });
        this.updateName(venueName, venueID);
    };
    resetBuildingForm = (value) => {
        this.setState({
            buildingForm: (
                <MDBSelect
                    id="selectBuilding"
                    getValue={this.handleSelectChangeBuilding}
                    className="form-control"
                >
                    <MDBSelectInput
                        className="selectInput selectBuilding"
                        selected="Choose your building"
                    />
                </MDBSelect>
            ),
        });
        this.setState({
            floorForm: (
                <MDBSelect
                    id="selectFloor"
                    getValue={this.handleSelectChangeFloor}
                    className="form-control"
                >
                    <MDBSelectInput
                        className="selectInput selectFloor"
                        selected="Choose your floor"
                    />
                </MDBSelect>
            ),
        });
        setTimeout(() => {
            this.handleSelectChangeUnit(value);
        }, 1);
    };
    handleSelectChangeUnit = async (value) => {
        await this.setState({
            selectedUnit: value,
        });

        if (this.state.unitData !== undefined) {
            this.setState({
                // selectedUnit: value,
                optionTemplateBuilding: [],
                // optionTemplateFloor: [],
                selectedBuilding: undefined,
                selectedFloor: undefined,
            });

            let optionTemplateBuilding = [];
            this.setState({
                optionTemplateBuilding: [],
            });
            Object.keys(this.state.unitData[value]).map((oneKey, i) => {
                if (oneKey === "DEFAULT") {
                    optionTemplateBuilding.push({
                        value: oneKey,
                        name: oneKey,
                    });
                } else {
                    optionTemplateBuilding.push({
                        value: oneKey,
                        name: oneKey,
                    });
                }
                return true;
            });
            this.setState({
                optionTemplateBuilding: optionTemplateBuilding,
            });
        }
    };
    handleSelectChangeUnitInitial = async (value) => {
        await this.setState({
            selectedUnit: value,
        });

        if (this.state.unitData !== undefined) {
            this.setState({
                // selectedUnit: value,
                optionTemplateBuilding: [],
                // optionTemplateFloor: [],
                // selectedBuilding: undefined,
                // selectedFloor: undefined,
            });

            let optionTemplateBuilding = [];
            this.setState({
                optionTemplateBuilding: [],
            });
            Object.keys(this.state.unitData[value]).map((oneKey, i) => {
                if (oneKey === "DEFAULT") {
                    optionTemplateBuilding.push({
                        value: oneKey,
                        name: oneKey,
                    });
                } else {
                    optionTemplateBuilding.push({
                        value: oneKey,
                        name: oneKey,
                    });
                }
                return true;
            });
            this.setState({
                optionTemplateBuilding: optionTemplateBuilding,
            });
        }
    };
    resetFloorForm = (value) => {
        setTimeout(() => {
            this.handleSelectChangeBuilding(value);
        }, 1);
    };
    handleSelectChangeBuilding = (selectedBuilding) => {
        if (
            selectedBuilding !== null &&
            this.state.selectedUnit !== undefined
        ) {
            // console.log("ResidentNavigation - handleSelectChangeBuilding - selectedBuilding: ", selectedBuilding)
            // console.log("ResidentNavigation - handleSelectChangeBuilding - selectedUnit: ", selectedUnit)
            this.setState({
                selectedBuilding: selectedBuilding,
                // selectedUnit: selectedUnit
            });
            let optionTemplateFloor = [];
            Object.keys(
                this.state.unitData[this.state.selectedUnit][selectedBuilding]
            ).map((oneKey, i) => {
                if (oneKey === "DEFAULT") {
                    optionTemplateFloor.push({ value: oneKey, name: oneKey });
                } else {
                    optionTemplateFloor.push({ value: oneKey, name: oneKey });
                }
                return true;
            });
            // console.log("handleSelectChangeBuilding: optionTemplateFloor: ", optionTemplateFloor)
            this.setState({
                optionTemplateFloor: optionTemplateFloor,
            });
        }
    };
    // handleSelectChangeFloor = async(selectedFloor, selectedUnit, selectedBuilding) => {
    //  console.log("ResidentNavigation - handleSelectChangeFloor - selectedUnit: ", selectedUnit)
    //  console.log("ResidentNavigation - handleSelectChangeFloor - selectedFloor: ", selectedFloor)
    //  console.log("ResidentNavigation - handleSelectChangeFloor - selectedBuilding: ", selectedBuilding)
    //  await this.setState({
    //   unitID_internal:
    //     this.state.unitData
    //      [this.state.selectedUnit === undefined ? selectedUnit : this.state.selectedUnit]
    //      [this.state.selectedBuilding === undefined ? selectedBuilding : this.state.selectedBuilding]
    //      [selectedFloor]["UnitID_internal"],
    //   unitID_internal1:
    //   this.state.unitData
    //   [this.state.selectedUnit === undefined ? selectedUnit : this.state.selectedUnit]
    //   [this.state.selectedBuilding === undefined ? selectedBuilding : this.state.selectedBuilding]
    //   [selectedFloor]["UnitID_internal"],
    // });
    //   if (selectedFloor !== null) {
    //     this.setState({ selectedFloor: selectedFloor });

    //     // console.log("Resident Mgmt/this.state.unitData: ", this.state.unitID_internal1)
    //     // console.log("Resident Mgmt/HandleSelectChangeFloor: ", this.state.unitData
    //     //  [this.state.selectedUnit === undefined ? selectedUnit : this.state.selectedUnit]
    //     //  [this.state.selectedBuilding === undefined ? selectedBuilding : this.state.selectedBuilding]
    //     //  [selectedFloor]["UnitID_internal"])
    //     // console.log("Resident Mgmt/SelectedFloor/Unit/Building: ", selectedFloor, selectedUnit, selectedBuilding)
    //   }
    // };
    handleSelectChangeFloor = (value) => {
        if (value !== null) {
            this.setState({ selectedFloor: value });
            this.setState({
                unitID_internal:
                    this.state.unitData[this.state.selectedUnit][
                        this.state.selectedBuilding
                    ][value]["UnitID_internal"],
            });
        }
    };
    updateName = (value, index) => {
        sessionStorage.setItem("venueName", value);
        sessionStorage.setItem("venueID", index);
        this.setState({
            venueID: index,
            venueName: value,
        });
    };
    onEditorHeaderStateChange = (editorHeaderState) => {
        this.setState({
            editorHeaderState,
            editorHeaderContent: draftToHtml(
                convertToRaw(editorHeaderState.getCurrentContent())
            ),
        });
    };
    onEditorMessageStateChange = (editorMessageState) => {
        this.setState({
            editorMessageState,
            editorMessageContent: draftToHtml(
                convertToRaw(editorMessageState.getCurrentContent())
            ),
        });
    };
    handleKeyDown = (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();
            var value = this.state.value.trim();
            if (value && this.isValid(value)) {
                this.setState({
                    emailAddresses: [
                        ...this.state.emailAddresses,
                        this.state.value,
                    ],
                    value: "",
                });
            }
        }
    };
    handleChange = (evt) => {
        this.setState({
            value: evt.target.value,
            error: null,
        });
    };
    handleDelete = (item) => {
        this.setState({
            emailAddresses: this.state.emailAddresses.filter((i) => i !== item),
        });
    };
    handlePaste = (evt) => {
        evt.preventDefault();
        var paste = evt.clipboardData.getData("text");
        var emails = paste.match(/[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/g);
        if (emails) {
            var toBeAdded = emails.filter((email) => !this.isInList(email));
            this.setState({
                emailAddresses: [...this.state.emailAddresses, ...toBeAdded],
            });
        }
    };
    isValid(email) {
        let error = null;
        if (this.isInList(email)) {
            error = `${email} has already been added.`;
        }
        if (!this.isEmail(email)) {
            error = `${email} is not a valid email address.`;
        }
        if (error) {
            this.setState({ error });
            return false;
        }
        return true;
    }
    isInList(email) {
        return this.state.emailAddresses.includes(email);
    }
    isEmail(email) {
        return /[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/.test(email);
    }
    changeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };
    changeHandlerPhone = (event) => {
        this.setState({ subscriberPhone: event });
    };
    submitSendEmail = (event) => {
        event.preventDefault();
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            EmailAddresses: this.state.emailAddresses,
            Subject: this.state.emailSubject,
            title: this.state.emailTitle,
            header: this.state.editorHeaderContent,
            message: this.state.editorMessageContent,
            EmailFromARN:
                "arn:aws:ses:us-east-1:450961373892:identity/vault-admin@roamingiq.com",
            actionbutton: " ",
        };
        this.setState({ sendEmail: true });
        axios
            .post("/operatorconsole/subscribers/sendEmail", body, {
                headers: {},
            })
            .then(
                (response) => {
                    toast.success("Email Sent!", {
                        closeButton: false,
                    });
                    // this.toggleSendEmailSubscribers();
                    this.setState({
                        sendEmail: false,
                        emailAddresses: [],
                    });
                },
                (error) => {
                    toast.error("There was an error", {
                        closeButton: false,
                    });
                    // this.toggleSendEmailSubscribers();
                    this.setState({ sendEmail: false });
                }
            );
    };
    toggleModifyResident = (active) => {
        this.setState({
            modalModifyResident: !this.state.modalModifyResident,
        });
    };
    toggleAddResident = () => {
        this.setState({
            modalAddNewSubscriber: !this.state.modalAddNewSubscriber,
        });
    };
    toggleAddResidentResubmit = () => {
        this.setState({
            modalAddNewSubscriberResubmit:
                !this.state.modalAddNewSubscriberResubmit,
        });
    };
    toggleEndedSubscriptionInfo = (active) => {
        this.setState({
            modalEndedSubscriptionInfo: !this.state.modalEndedSubscriptionInfo,
        });
    };
    resubmitModify = (
        email,
        firstName,
        lastName,
        phoneNumber,
        selectedLanguage,
        venueID,
        optionTemplateUnits,
        optionTemplateBuilding,
        unitName,
        buildingName,
        floorName,
        selectedServicePlanID,
        unitID_internal
    ) => {
        this.handleSelectChangeVenue(venueID, unitName);
        // console.log("ResubmitModify: ", unitName)
        this.setState({
            email: email,
            firstName: firstName,
            lastName: lastName,
            phoneNumber: phoneNumber,
            selectedLanguage: selectedLanguage,
            // venueID: venueID,
            optionTemplateUnits: optionTemplateUnits,
            optionTemplateBuilding: optionTemplateBuilding,
            selectedUnit: unitName,
            selectedBuilding: buildingName,
            selectedFloor: floorName,
            selectedServicePlanID: selectedServicePlanID,
            unitID_internal: unitID_internal,
        });
        this.toggleEndedSubscriptionInfo();
        this.toggleAddResidentResubmit();
    };
    changeHandlerRadio = (nr) => () => {
        this.handleSelectChangeVenue();
        // console.log(nr);
        this.setState({
            setSearchBy: nr,
            subscriberPhone: undefined,
            subscriberEmail: undefined,
            searchResults: undefined,
        });
    };
    render() {
        // console.log("PaymentFailed: ", this.state.paymentFailed);
        // console.log("Active: ", this.state.active);
        // console.log(this.state.paymentFailed && (this.state.active === "false" || this.state.active === undefined));
        // console.log("ModalAddPayment: ", this.state.modalAddPayment);
        let url = [
            "roamingiq.wifikey.link",
            "roamingiq.roamingiqtest.com",
            "demo.roamingiq.com",
            "localhost",
        ];
        if (url.includes(window.location.hostname)) {
            showSendEmail = true;
        }
        if (this.state.postMessage) {
            var h = document.getElementById("overlaySubscriberManagement");
            h.style.display = "block";
            setTimeout(function () {
                h.style.display = "block";
            }, 5000);
            setTimeout(() => {
                h.style.display = "none";
                this.setState({ postMessage: "" });
            }, 6000);
        }
        const { editorHeaderState, editorMessageState } = this.state;
        return (
            <>
                {this.state.paymentFailed &&
                (this.state.active === "false" ||
                    this.state.active === undefined) ? (
                    <MDBModal
                        isOpen={this.state.modalAddPayment}
                        toggle={this.togglePayment}
                        size="md"
                        className="form"
                        centered
                    >
                        <MDBModalHeader toggle={this.togglePayment}>
                            Modify Billing Credit Card Info
                        </MDBModalHeader>
                        <MDBModalBody className="error">
                            <h4>
                                The subscriber's credit card info needs to be
                                updated for billing purposes.
                            </h4>
                            <MDBBtn
                                color="danger"
                                className="btn Ripple-parent btn-default centered upgradePlan"
                                href={
                                    "/update/checkout?ui=" +
                                    window.location.pathname.slice(1)
                                }
                            >
                                Modify Billing CC
                            </MDBBtn>
                        </MDBModalBody>
                    </MDBModal>
                ) : (
                    ""
                )}
                {this.state.lastProcessed > 0 ? (
                    <>
                        <MDBModal
                            isOpen={this.state.modalError}
                            toggle={this.toggle}
                            size="md"
                            className="form"
                            centered
                        >
                            <MDBModalHeader toggle={this.toggleError}>
                                <h4>Error Uploading Subscribers</h4>
                            </MDBModalHeader>
                            <MDBModalBody>
                                {this.state.lastProcessed === 1 ? (
                                    <p>
                                        Line {this.state.lastProcessed} (Unit:{" "}
                                        {this.state.unitUnprocessed}, Building:{" "}
                                        {this.state.buildingUnprocessed}, Floor:{" "}
                                        {this.state.floorUnprocessed}) could not
                                        be processed. Re-upload the CSV after
                                        fixing line 1.
                                    </p>
                                ) : (
                                    <p>
                                        Line {this.state.lastProcessed} (Unit:{" "}
                                        {this.state.unitUnprocessed}, Building:{" "}
                                        {this.state.buildingUnprocessed}, Floor:{" "}
                                        {this.state.floorUnprocessed}) could not
                                        be processed. Re-upload the CSV after
                                        fixing line {this.state.lastProcessed}{" "}
                                        and removing lines 1-
                                        {this.state.lastProcessed - 1}.
                                    </p>
                                )}
                            </MDBModalBody>
                        </MDBModal>
                    </>
                ) : (
                    ""
                )}
                {this.state.loading_upload === true ? (
                    <div
                        className="overlay"
                        id="overlay"
                        style={{ display: "block" }}
                    >
                        <div className="overlayProgressBar">
                            <MDBProgress
                                material
                                value={this.state.progress}
                                height="20px"
                                color="danger"
                                striped
                            />
                        </div>
                    </div>
                ) : (
                    ""
                )}
                {this.props.loading === true ? (
                    <div
                        className="overlay"
                        id="overlay"
                        style={{ display: "block" }}
                    >
                        <div className="overlayMessage">
                            <div
                                className="spinner-border spinner-border"
                                role="status"
                            >
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                ) : (
                    ""
                )}
                <MDBModalHeader toggle={this.props.toggle}>
                    <h4>Subscriber Management</h4>
                </MDBModalHeader>
                <MDBModalBody>
                    <div className="managementModal">
                        <MDBRow>
                            <MDBCol size="12" md="6" lg="4">
                                <div
                                    className="managementModalButton"
                                    onClick={this.toggleAddNewSubscriber}
                                >
                                    <MDBIcon icon="user-plus" />
                                    <p>Add New Subscriber</p>
                                </div>
                            </MDBCol>
                            <MDBCol size="12" md="6" lg="4">
                                <div
                                    className="managementModalButton"
                                    onClick={this.toggleViewModifySubscriber}
                                >
                                    <MDBIcon icon="edit" />
                                    <p>View/Modify Subscriber</p>
                                </div>
                            </MDBCol>
                            {sessionStorage.getItem("customerSupport") > 0 ? (
                                ""
                            ) : (
                                <>
                                    <MDBCol size="12" md="6" lg="4">
                                        <div
                                            className="managementModalButton"
                                            onClick={
                                                this
                                                    .toggleBulkDownloadSubscribers
                                            }
                                        >
                                            <MDBIcon icon="cloud-download-alt" />
                                            <p>Export Subscribers</p>
                                        </div>
                                    </MDBCol>
                                    <MDBCol size="12" md="6" lg="4">
                                        <div
                                            className="managementModalButton"
                                            onClick={
                                                this.toggleBulkUploadSubscribers
                                            }
                                        >
                                            <MDBIcon icon="cloud-upload-alt" />
                                            <p>Upload Subscribers</p>
                                        </div>
                                    </MDBCol>
                                </>
                            )}
                            <MDBCol size="12" md="6" lg="4">
                                <div
                                    className="managementModalButton"
                                    onClick={this.toggleSearchSubscriber}
                                >
                                    <MDBIcon icon="search" />
                                    <p>Subscriber Search</p>
                                </div>
                            </MDBCol>
                            {/* <MDBCol md="4">
                <div className="managementModalButton" onClick={this.toggleAbandonedSubscribersTable}>
                  <MDBIcon icon="shopping-cart" />
                  <p>Abandoned signups</p>
                </div>
              </MDBCol>
              <MDBCol md="4">
                <div className="managementModalButton" onClick={this.toggleInvoicesTable}>
                  <MDBIcon icon="file-invoice-dollar" />
                  <p>Invoices</p>
                </div>
              </MDBCol>
              <MDBCol md="4">
                <div className="managementModalButton" onClick={this.togglePaymentUpdateRequiredTable}>
                  <MDBIcon icon="credit-card" />
                  <p>Payment update required</p>
                </div>
              </MDBCol> */}
                            {showSendEmail ? (
                                <MDBCol size="12" md="6" lg="4">
                                    <div
                                        className="managementModalButton"
                                        onClick={
                                            this.toggleSendEmailSubscribers
                                        }
                                    >
                                        <MDBIcon icon="envelope-open" />
                                        <p>Send E-Mail</p>
                                    </div>
                                </MDBCol>
                            ) : (
                                ""
                            )}
                        </MDBRow>
                    </div>
                </MDBModalBody>
                <MDBModal
                    isOpen={this.state.modalAddNewSubscriber}
                    toggle={this.toggleAddNewSubscriber}
                    size="lg"
                    className="form"
                    centered
                >
                    <AddSubscriber
                        toggleAddNewSubscriber={this.toggleAddNewSubscriber}
                        submitHandler={this.submitHandler}
                        loading={this.state.loading}
                        attributeForm={this.state.attributeForm}
                        venueID={this.state.venueID}
                        venueName={this.state.venueName}
                        unitForm={this.state.unitForm}
                        selectedUnit={this.state.selectedUnit}
                        buildingForm={this.state.buildingForm}
                        selectedBuilding={this.state.selectedBuilding}
                        floorForm={this.state.floorForm}
                        selectedFloor={this.state.selectedFloor}
                        hasUpdated={this.state.hasUpdated}
                        hasUpdatedUnit={this.state.hasUpdatedUnit}
                        hasUpdatedBuilding={this.state.hasUpdatedBuilding}
                        hasUpdatedFloor={this.state.hasUpdatedFloor}
                        unitID_internal={this.state.unitID_internal}
                        optionTemplateUnits={this.state.optionTemplateUnits}
                        optionTemplateBuilding={
                            this.state.optionTemplateBuilding
                        }
                        optionTemplateFloor={this.state.optionTemplateFloor}
                        optionTemplateServicePlans={
                            this.state.optionTemplateServicePlans
                        }
                        handleSelectChangeUnit={this.handleSelectChangeUnit}
                        handleSelectChangeBuilding={
                            this.handleSelectChangeBuilding
                        }
                        handleSelectChangeFloor={this.handleSelectChangeFloor}
                        getVenues={this.getVenues}
                        optionTemplateVenues={this.props.optionTemplateVenues}
                        handleSelectChangeVenue={this.handleSelectChangeVenue}
                        clearDropDown={this.state.clearDropDown}
                    />
                </MDBModal>
                <MDBModal
                    isOpen={this.state.modalViewModifySubscriber}
                    toggle={this.toggle}
                    size="fluid"
                    className="form"
                    centered
                >
                    <ViewModifySubscribers
                        toggleViewModifySubscriber={
                            this.toggleViewModifySubscriber
                        }
                        attributeForm={this.state.attributeForm}
                        venueID={this.state.venueID}
                        venueName={this.state.venueName}
                        firstID={this.state.firstID}
                        showUpdate={this.props.showUpdate}
                        cancelInputType={this.props.cancelInputType}
                        getVenues={this.getVenues}
                        optionTemplateVenues={this.props.optionTemplateVenues}
                        optionTemplateUnits={this.state.optionTemplateUnits}
                        handleSelectChangeVenue={this.handleSelectChangeVenue}
                        handleSelectChangeUnit={this.handleSelectChangeUnit}
                        handleSelectChangeBuilding={
                            this.handleSelectChangeBuilding
                        }
                        getVenueDetails={this.getVenueDetails}
                        submitHandler={this.submitHandler}
                        clearDropDown={this.state.clearDropDown}
                        updateName={this.props.updateName}
                    />
                </MDBModal>
                <MDBModal
                    isOpen={this.state.modalResendActivation}
                    toggle={this.toggle}
                    size="lg"
                    className="form"
                    centered
                >
                    <ResendActivation
                        toggleResendActivation={this.toggleResendActivation}
                        recentSubscribers={this.props.activeData}
                        submitHandlerResendActivation={
                            this.submitHandlerResendActivation
                        }
                        loading={this.state.loading}
                    />
                </MDBModal>
                <MDBModal
                    isOpen={this.state.modalServicesEndingSoon}
                    toggle={this.toggle}
                    size="fluid"
                    className="form"
                    centered
                >
                    <ServicesEndingSoon
                        toggleServicesEndingSoon={this.toggleServicesEndingSoon}
                        expirations={this.props.expirations}
                        venueID={this.props.venueID}
                        venueName={this.props.venueNameSelected}
                        updateName={this.props.updateName}
                    />
                </MDBModal>
                <MDBModal
                    isOpen={this.state.modalDownloadSubscribers}
                    toggle={this.toggle}
                    size="md"
                    className="form"
                    centered
                >
                    <DownloadSubscribers
                        toggleDownloadSubscribers={
                            this.toggleBulkDownloadSubscribers
                        }
                        attributeForm={this.state.attributeForm}
                        venueID={this.state.venueID}
                        venueName={this.state.venueName}
                        loadingVenueDetails={this.state.loadingVenueDetails}
                        hasUpdated={this.state.hasUpdated}
                        optionTemplateVenues={this.props.optionTemplateVenues}
                        handleSelectChangeVenue={this.handleSelectChangeVenue}
                        clearDropDown={this.state.clearDropDown}
                    />
                </MDBModal>
                <MDBModal
                    isOpen={this.state.modalBulkUploadSubscribers}
                    toggle={this.toggle}
                    size="lg"
                    className="form"
                    centered
                >
                    <UploadSubscribers
                        toggleBulkUploadSubscribers={
                            this.toggleBulkUploadSubscribers
                        }
                        attributeForm={this.state.attributeForm}
                        venueID={this.state.venueID}
                        venueName={this.state.venueName}
                        updateName={this.props.updateName}
                        loadingVenueDetails={this.state.loadingVenueDetails}
                        hasUpdated={this.state.hasUpdated}
                        optionTemplateVenues={this.props.optionTemplateVenues}
                        handleSelectChangeVenue={this.handleSelectChangeVenue}
                        getVenues={this.getVenues}
                        clearDropDown={this.state.clearDropDown}
                    />
                </MDBModal>
                <MDBModal
                    isOpen={this.state.modalAbandonedSubscribersTable}
                    toggle={this.toggle}
                    size="fluid"
                    className="form"
                    centered
                >
                    <AbandonedSubscribers
                        toggleAbandonedSubscribersTable={
                            this.toggleAbandonedSubscribersTable
                        }
                    />
                </MDBModal>
                <MDBModal
                    isOpen={this.state.modalInvoicesTable}
                    toggle={this.toggle}
                    size="fluid"
                    className="form"
                    centered
                >
                    <InvoicesTable
                        toggleInvoicesTable={this.toggleInvoicesTable}
                    />
                </MDBModal>
                <MDBModal
                    isOpen={this.state.modalPaymentUpdateRequiredTable}
                    toggle={this.toggle}
                    size="fluid"
                    className="form"
                    centered
                >
                    <PaymentUpdateRequiredTable
                        togglePaymentUpdateRequiredTable={
                            this.togglePaymentUpdateRequiredTable
                        }
                    />
                </MDBModal>
                <MDBModal
                    isOpen={this.state.modalSearchSubscriber}
                    toggle={this.toggle}
                    size="fluid"
                    className="form"
                    centered
                >
                    <SearchSubscribers
                        toggleSearchSubscriber={this.toggleSearchSubscriber}
                        loading={this.state.loading}
                        showUpdate={this.props.showUpdate}
                        cancelInputType={this.props.cancelInputType}
                        optionTemplateVenues={this.props.optionTemplateVenues}
                        optionTemplateUnits={this.state.optionTemplateUnits}
                        optionTemplateBuilding={
                            this.state.optionTemplateBuilding
                        }
                        optionTemplateFloor={this.state.optionTemplateFloor}
                        hasUpdated={this.props.hasUpdated}
                        venueInfo={this.state.venueInfo}
                        handleSelectChangeVenue={this.handleSelectChangeVenue}
                        handleSelectChangeUnit={this.handleSelectChangeUnit}
                        handleSelectChangeUnitInitial={
                            this.handleSelectChangeUnitInitial
                        }
                        handleSelectChangeBuilding={
                            this.handleSelectChangeBuilding
                        }
                        handleSelectChangeFloor={this.handleSelectChangeFloor}
                        venueID={this.state.venueID}
                        clearDropDown={this.state.clearDropDown}
                        getVenues={this.getVenues}
                        unitID_internal={this.state.unitID_internal}
                        getVenueDetails={this.getVenueDetails}
                        submitHandler={this.submitHandler}
                        optionTemplateServicePlans={
                            this.state.optionTemplateServicePlans
                        }
                        getVenueUnitDetails={this.getVenueUnitDetails}
                        findSubscribers={this.findSubscribers}
                        searchResults={this.state.searchResults}
                        modalEndedSubscriptionInfo={
                            this.state.modalEndedSubscriptionInfo
                        }
                        modalAddNewSubscriberResubmit={
                            this.state.modalAddNewSubscriberResubmit
                        }
                        modalModifyResident={this.state.modalModifyResident}
                        loadingVenueDetails={this.state.loadingVenueDetails}
                        venueUnitData={this.state.venueUnitData}
                        unitData={this.state.unitData}
                        venueName={this.state.venueName}
                        SubscriberGivenName={this.state.SubscriberGivenName}
                        SubscriberFamilyName={this.state.SubscriberFamilyName}
                        SubscriberEmail={this.state.SubscriberEmail}
                        SubscriberPhoneNumber={this.state.SubscriberPhoneNumber}
                        receivetext={this.state.contactpref_email}
                        receivephone={this.state.contactpref_sms}
                        contactpref_sms={this.state.contactpref_sms}
                        contactpref_email={this.state.contactpref_email}
                        SubscriberLocale={this.state.SubscriberLocale}
                        UnitFloor={this.state.UnitFloor}
                        UnitBuilding={this.state.UnitBuilding}
                        StartDateTime={this.state.StartDateTime}
                        EndDateTime={this.state.EndDateTime}
                        UnitID={this.state.UnitID}
                        submitModify={this.submitModify}
                        submitDelete={this.deleteResident}
                        SSID={this.state.SSID}
                        servicePlanID={this.state.servicePlanID}
                        servicePlan={this.state.servicePlan}
                        availablePlans={this.state.availablePlans}
                        // getVenueDetails={this.getVenueDetails}
                        vlan={this.state.vlan}
                        status={this.state.status}
                        realpageSyncDate={this.state.realpageSyncDate}
                        subscriberManagedviaSSO={
                            this.state.subscriberManagedviaSSO
                        }
                        paymentFailed={this.state.paymentFailed}
                        StripeClientSecret={this.state.StripeClientSecret}
                        country={this.state.country}
                        subscriptionDiscount={this.state.subscriptionDiscount}
                        venueDiscount={this.state.venueDiscount}
                        externalSubscriberID={this.state.externalSubscriberID}
                        selectedServicePlanPrice={
                            this.state.selectedServicePlanPrice
                        }
                        stripeClientParentSecret={
                            this.state.stripeClientParentSecret
                        }
                        setupIntentID={this.state.setupIntentID}
                        toggleEndedSubscriptionInfo={
                            this.toggleEndedSubscriptionInfo
                        }
                        toggleAddResidentResubmit={
                            this.toggleAddResidentResubmit
                        }
                        unitForm={this.state.unitForm}
                        selectedUnit={this.state.selectedUnit}
                        buildingForm={this.state.buildingForm}
                        selectedBuilding={this.state.selectedBuilding}
                        floorForm={this.state.floorForm}
                        selectedFloor={this.state.selectedFloor}
                        resubmitModify={this.resubmitModify}
                        selectedServicePlanID={this.state.selectedServicePlanID}
                        changeHandler={this.changeHandler}
                        changeHandlerPhone={this.changeHandlerPhone}
                        changeHandlerRadio={this.changeHandlerRadio}
                        subscriberPhone={this.state.subscriberPhone}
                        toggleModifyResident={this.toggleModifyResident}
                        setSearchBy={this.state.setSearchBy}
                        loadingRecreateSubscription={
                            this.state.loadingRecreateSubscription
                        }
                        togglePayment={this.togglePayment}
                        modalAddPayment={this.state.modalAddPayment}
                        loadingSearchResults={this.state.loadingSearchResults}
                        active={this.state.active}
                        updateName={this.props.updateName}
                    />
                </MDBModal>
                <MDBModal
                    isOpen={this.state.modalSendEmailSubscribers}
                    toggle={this.toggle}
                    size="fluid"
                    className="form"
                    centered
                >
                    <MDBModalHeader toggle={this.toggleSendEmailSubscribers}>
                        <h4>Send Email to Subscribers</h4>
                    </MDBModalHeader>
                    <MDBModalBody>
                        <form
                            className="needs-validation"
                            onSubmit={this.submitSendEmail}
                            noValidate
                        >
                            <label
                                htmlFor="defaultFormLoginEmailEx"
                                className="black-text"
                            >
                                Email Addresses (separate each by comma)
                            </label>
                            <br />
                            {this.state.emailAddresses.map((item) => (
                                <div className="tag-item" key={item}>
                                    {item}
                                    <button
                                        type="button"
                                        className="button"
                                        onClick={() => this.handleDelete(item)}
                                    >
                                        &times;
                                    </button>
                                </div>
                            ))}
                            <input
                                className={
                                    "form-control input " +
                                    (this.state.error && " has-error")
                                }
                                value={this.state.value}
                                placeholder="Type or paste email addresses and press `Enter`..."
                                onKeyDown={this.handleKeyDown}
                                onChange={this.handleChange}
                                onPaste={this.handlePaste}
                            />
                            {this.state.error && (
                                <p className="error">{this.state.error}</p>
                            )}
                            <br />
                            <label
                                htmlFor="defaultFormLoginEmailEx"
                                className="black-text"
                            >
                                Email Subject
                            </label>
                            <input
                                name="emailSubject"
                                id="emailSubject"
                                className="form-control"
                                onChange={this.changeHandler}
                                value={this.state.emailSubject}
                            />
                            <br />
                            <label
                                htmlFor="defaultFormLoginEmailEx"
                                className="black-text"
                            >
                                Title
                            </label>
                            <input
                                name="emailTitle"
                                id="emailTitle"
                                className="form-control"
                                onChange={this.changeHandler}
                                value={this.state.emailTitle}
                            />
                            <br />
                            <label
                                htmlFor="defaultFormLoginEmailEx"
                                className="black-text"
                            >
                                Header
                            </label>
                            <Editor
                                name="emailHeader"
                                className="form-control"
                                editorState={editorHeaderState}
                                editorClassName="form-control editorHeader"
                                onEditorStateChange={
                                    this.onEditorHeaderStateChange
                                }
                                toolbar={{
                                    options: [
                                        "inline",
                                        "blockType",
                                        "fontSize",
                                        "fontFamily",
                                        "list",
                                        "textAlign",
                                        "link",
                                    ],
                                }}
                            />
                            <br />
                            <label
                                htmlFor="defaultFormLoginEmailEx"
                                className="black-text"
                            >
                                Message
                            </label>
                            <Editor
                                className="form-control"
                                editorState={editorMessageState}
                                editorClassName="form-control editorMessage"
                                onEditorStateChange={
                                    this.onEditorMessageStateChange
                                }
                                toolbar={{
                                    options: [
                                        "inline",
                                        "blockType",
                                        "fontSize",
                                        "fontFamily",
                                        "list",
                                        "textAlign",
                                        "link",
                                    ],
                                }}
                            />
                            <div className="text-right submitButton">
                                <MDBBtn color="danger" type="submit">
                                    {this.state.sendEmail === true ? (
                                        <div
                                            className="spinner-border spinner-border-sm"
                                            role="status"
                                        >
                                            <span className="sr-only">
                                                Loading...
                                            </span>
                                        </div>
                                    ) : (
                                        "Send Email"
                                    )}
                                </MDBBtn>
                            </div>
                        </form>
                    </MDBModalBody>
                </MDBModal>
            </>
        );
    }
}
export default withTranslation()(SubscriberManagementNavigation);
